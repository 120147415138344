import React from "react";

function Donations() {

    return(
        <div className="page">
            <h1> Support Fere Project</h1>
            <p>All donations will go toward school financial assistance. </p>
          
            
            <h3> Paypal donations coming soon</h3>
        </div>
    )
}

export default Donations;
